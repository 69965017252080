/// <reference path="libs/jquery.js" />

if (!window.console) {
	window.console = {
		log: function () { }
	};
}

(function ($, console) {

	var init = function () {

		console.log('Initializing...');

		//$('.js-call-me-btn').on('click', function() {
		//	var form = $(this).closest('form');

		//	// form.find('.hidden-xs-up').removeClass('hidden-xs-up');

		//	console.log(form);

		//	return false;
		//});

	};

	$(document).ready(init);

})(window.jQuery, window.console);



/* jshint ignore:start */
var MTIProjectId = '4e0e3cd5-ab2b-4776-9e8f-560c46f28fc0';
(function () {
	var mtiTracking = document.createElement('script');
	mtiTracking.type = 'text/javascript';
	mtiTracking.async = 'true';
	mtiTracking.src = '/fonts/mtiFontTrackingCode.js';
	(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mtiTracking);
})();
/* jshint ignore:end */
